import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useCursor} from "../Cursor/Cursor";
import { ScrollTrigger, gsap, useGSAP } from '@repo/utils/gsap'
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, setCssVars} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Button from "../common/Button";
import {
    useExtendedTheme,
    useIsDesktop,
    useIsMobile,
    useLoadedManager,
    useScrollContext,
    useWindowSize
} from "../../../index";
import revealAnimationContainer from "../common/RevealAnimationContainer";
import BlobPortal from "./Blob";
import PortalLens from "../common/StaticLens/PortalLens";
// import BandVideo from '../../assets/video/band-white-horizontal.mp4'
import BandVideo from '../../assets/video/band-white-square.mp4'
import Icon from "../common/Icon/Icon";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import Transition from "../common/StaticLens/Transition";
import Tag from "../common/Tag";
import {AnimatePresence, motion} from "framer-motion";
import {useRouter} from "next/router";
import useIsTablet from "../../hooks/useIsTablet";
import useMobileDevice from "../../hooks/useMobileDevice";
import useMediaQuery from "../../hooks/useMediaQuery";

export const ID = 'areas-of-expertise-module'

const REVEAL_ANIMATION_TARGET_CLASS = 'areas-of-expertise-reveal-up-animation-target';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

const StyledAreasOfExpertiseContainer = styled.div`
    width: 100%;
    height: ${({$height}) => $height ? $height : 120}vh;
    overflow: hidden;
    //background: ${({$backgroundColor, theme}) => $backgroundColor ? $backgroundColor : theme.colors.lightGray};
    position: relative;
    display: block;
    @media(min-width: 1024px) {
        display: block;
    }
`

const StyledAreasOfExpertisePinnedWrapper = styled.div`
    //background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
`

const StyledHeaderContainer = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const StyledHeader = styled.div`
	width: 100%;
    opacity: 0;
    transform: translateY(100px);
    //position: absolute;
    // top: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)};
    text-align: center;
	color: var(--secondary-color);
	font-weight: ${({ theme }) => theme.fontWeight ? theme.fontWeight.semiBold : 600};
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: normal;
    //margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
    position: absolute;
    top: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    em {
        font-weight: ${({ theme }) => theme.fontWeight.light};
        font-style: italic;
    }
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        //top: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        //margin-top: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
        top: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
    }
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(76, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(136, BREAKPOINTS.DESKTOP)};
        letter-spacing: -4.64px;
        margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        margin-top: 0;
        position: absolute;
        top: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
	}
    @media(min-width: 1440px){
        margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        top: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
        @media(min-height: 1000px) {
            top: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        }
    }
`;

const StyledSingleServiceWrapper = styled.div`
    width: 100%;
    //background-color: ${({bgColor}) => bgColor};
    height: 100vh;
    display: flex;
    cursor: pointer;
    //padding: 10svh 0;
    //bordeR: 2px solid red;
    flex-direction: column;
    justify-content: center;
    //justify-content: flex-start;
    padding: 30px 0;
    align-items: center;
    position: relative;
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding: 50px 0 15px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
`

const HalfLine = styled.div`
    width: 10px;
    height: 100vw;
    position: absolute;
    top: 0;
    left: calc(50% - 5px);
    background-color: red;
`

const ServiceNameContainer = styled.div`
    position: relative;
    overflow: hidden;
    opacity: 0;
    width: calc(100% - 2 * var(--paddingX));
    margin-top: 57px;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: flex;
        flex-direction: column;
        width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
        margin-top: 0;
        padding-right: 5px;
    }
    @media(min-width: 1440px) {
        width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
    }
`

const StyledServiceNameWrapper = styled(motion.div)`
    //width: ${getCssSizeValue(400, BREAKPOINTS.DESKTOP)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    //text-align: right;
    //width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
    //width: calc(100% - 2 * var(--paddingX));
    margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    //background-color: red;
    //border: 2px solid red;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        //margin-bottom: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        //width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        margin-bottom: 0;
    }
    @media(min-width: 1440px) {
        //width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
        width: 100%;
    }
`

const ServiceIndex = styled.div`
    width: ${getCssSizeValue(29, BREAKPOINTS.MOBILE)};
    height: ${getCssSizeValue(29, BREAKPOINTS.MOBILE)};
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondary-color);
    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    line-height: normal;
    margin-right: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
        width: ${getCssSizeValue(37, BREAKPOINTS.TABLET)};
        height: ${getCssSizeValue(37, BREAKPOINTS.TABLET)};
        margin-right: ${getCssSizeValue(21, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
        height: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
        margin-right: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
    }
`

const ServiceName = styled.div`
    color: var(--secondary-color);
    text-transform: lowercase;
    font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.regular};
    letter-spacing: -0.88px;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(45, BREAKPOINTS.TABLET)};
        letter-spacing: -1.44;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        letter-spacing: -1.92px;
    }
`

const ServiceDescription = styled.div`
    display: none;
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        text-align: left;
        display: initial;
        color: var(--secondary-color);
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(31, BREAKPOINTS.DESKTOP)};
        padding-left: ${({$paddingLeft}) => `${$paddingLeft}px`};
        padding-right: ${getCssSizeValue(25, BREAKPOINTS.DESKTOP)};
    }
`

const StyledVideoMockupForMobile = styled.div`
    display: block;
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 360px;
        height: 360px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
    }
`

const StyledVideoWrapper = styled.div`
    width: 250px;
    height: 250px;
    position: absolute;
    top: calc(50% - 125px - 100px);
    left: calc(50% - 125px);
    //background-color: rgba(25,100,200,0.2);
    opacity: 1;
    border-radius: 50%;
    //overflow: hidden;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: 360px;
        height: 360px;
        //position: absolute;
        //top: calc(50% - 180px - 100px);
        top: calc(50% - 180px - 50px);
        left: calc(50% - 180px);
        //position: relative;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        //border: 2px solid red;
        position: relative;
        width: 476px;
        //width: 676px;
        height: 476px;
        top: unset;
        left: unset;
        margin-left: ${getCssSizeValue(76, BREAKPOINTS.DESKTOP)};
        margin-right: ${getCssSizeValue(76, BREAKPOINTS.DESKTOP)};
        //height: 676px;
        // width: ${getCssSizeValue(676, BREAKPOINTS.DESKTOP)};
        // height: ${getCssSizeValue(676, BREAKPOINTS.DESKTOP)};
        &.mobile-device {
            width: 360px;
            height: 360px;
        }
    }
    @media(min-width: 1440px) {
        width: 642px;
        height: 642px;
        //border: 2px solid red;
    }
    video {
        object-fit: cover;
        height: 100%;
    }
`

const StyledRangeContainer = styled.div`
    position: relative;
    overflow: hidden;
    //padding: 0 0 20px;
    opacity: 0;
    //border: 2px solid royalblue;
    width: calc(100% - 2 * var(--paddingX));
    min-height: ${getCssSizeValue(300, BREAKPOINTS.MOBILE)};
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        min-height: ${getCssSizeValue(300, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: calc((100% - 476px - 2 * var(--paddingX)) / 2);
        min-height: unset;
        //padding: 50px 0;
        padding: 20px 0;
    }
    @media(min-width: 1440px) {
        width: calc((100% - 676px - 2 * var(--paddingX)) / 2);
    }
`

const StyledRangeWrapper = styled(motion.div)`
    width: 100%;
`

const RangeText = styled.div`
    color: var(--secondary-color);
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.bold};
    letter-spacing: -0.64px;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(35, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(27, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-bottom: ${getCssSizeValue(38, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: normal;
        letter-spacing: -0.72px;
    }
`

const StyledCompetencesTagWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    row-gap: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    max-height: calc(${getCssSizeValue(10, BREAKPOINTS.MOBILE)} * 2 + 3 * 35px + 8px);
     margin-bottom: ${getCssSizeValue(25, BREAKPOINTS.MOBILE)};
    //margin-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
        column-gap: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
        row-gap: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        overflow: auto;
        max-height: unset;
        //margin-bottom: ${getCssSizeValue(85, BREAKPOINTS.DESKTOP)};
        margin-bottom: 0;
        column-gap: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
        row-gap: ${getCssSizeValue(15, BREAKPOINTS.DESKTOP)};
    }
`

const MoreTags = styled.p`
    color: ${({accentColor}) => `${accentColor}`};
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.bold};
    letter-spacing: -0.64px;
    margin-top: -10px;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(35, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(27, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
        margin-bottom: ${getCssSizeValue(38, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: normal;
        letter-spacing: -0.72px;
    }
    
`

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        justify-content: flex-start;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: none;
        &.mobile-device-landscape {
            display: flex;
            margin-top: 20px;
        }
        //display: flex;
    }
    
`

const RevealWrapper = revealAnimationContainer('div', REVEAL_ANIMATION_TARGET_CLASS)``

const StyledIconWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //display: none;
    transition: transform 0.2s linear, opacity 0s linear;
    transform-origin: center center;
    transform: rotate(-90deg);
    transform: ${({rotationMultiplier}) => rotationMultiplier === -1 ? `calc(-1 * 90deg)` : `rotate(calc(${rotationMultiplier} * (155deg / 5)  - 90deg))`};
    opacity: 0;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: block;
    }
    > div {
        max-width: 100%!important;
    }
    svg {
        width: 100%;
        height: 100%; 
        path {
            fill: ${({borderColor}) => borderColor};
            //fill : red!important;
        }
        
    }
`


const AreasOfExpertise = ({data}) => {
    const areasOfExpertiseRef = useRef();
    const pinnedWrapperRef = useRef();
    const BlobRef = useRef();
    const IconWrapperRef = useRef();
    const headingRef = useRef();
    const heading = data?.heading || '';
    const rangeText = data?.rangePlaceholder || '';
    const buttonText = data?.buttonText || '';
    const services = data?.chooseServices || [];
    const [activeItem, setActiveItem] = useState({...services[0], backgroundColor: null, id: -1})
    const isDesktop = useIsDesktop()
    const isMobileDevice = useMobileDevice()
    const orientationLandscape = useMediaQuery(`(orientation: landscape)`);
    const windowSize = useWindowSize()
    const portalLens = useCursor((state) => state.portalLens)
    const blobScale = windowSize.width > 1440 ? 1 : 0.75;
    const {setTheme, palette} = useExtendedTheme()
    const [initPalette, setInitPalette] = useState(palette)
    const router = useRouter();
    const [blobVideoSource, setBlobVideoSource] = useState(BandVideo)
    const serviceName = activeItem?.serviceName || ''
    const serviceDescription = activeItem?.serviceDescription || ''
    const serviceSlug = activeItem?.slug || '';
    const areaColorBackground = activeItem?.areaColorBackground?.hex || '#fff'
    const borderColor = activeItem?.borderColor?.hex || '#fff';
    const videoFile = activeItem?.videoFile?.url || '';
    const competences = activeItem?.chooseCompetences || [];
    const externalVideoUrl = activeItem?.externalVideoUrl?.url || '';
    const externalVideoWidth = activeItem?.externalVideoUrl?.width || 1;
    const externalVideoHeight = activeItem?.externalVideoUrl?.height || 1;
    const externalVideoAspectRatio = externalVideoWidth/externalVideoHeight

    // const setTheme = useCallback((props) => {
    //     console.log(props.palette);
    //     setExtendedTheme(props)
    // },[])

    useEffect(() => {
        portalLens.visible = true
    },[])

    useGSAP(() => {
        // ScrollTrigger.create({
        //     id: 'expertise',
        //     trigger: areasOfExpertiseRef.current,
        //     start: 'top top',
        //     end: '500px',
        //     // markers: true,
        //     // pin: true,
        //     // pinSpacing: false
        //     pinSpacing: true
        // })

        // ScrollTrigger.create({
        //     trigger: areasOfExpertiseRef.current,
        //     start: `top top+=1%`,
        //     endTrigger: areasOfExpertiseRef.current,
        //     onEnter() {
        //         gsap.to(`.${REVEAL_ANIMATION_TARGET_CLASS}`, { y: 0, stagger: 0.1 })
        //     },
        //     onLeaveBack() {
        //         gsap.to(`.${REVEAL_ANIMATION_TARGET_CLASS}`, { y: '100%', stagger: -0.1 })
        //     }
        // })

        ScrollTrigger.create({
            id: 'pinned-areas-of-expertise-wrapper',
            trigger: areasOfExpertiseRef.current,
            start: 'top top',
            end: 'bottom bottom',
            // markers: true,
            pin: pinnedWrapperRef.current,
            pinSpacing: false,
            onEnter: () => {
                // setRotationMultiplier(activeItem.id);
                gsap.to('#service-container', {
                    opacity: 1,
                    duration: 0.2,
                    delay: 0.4,
                    ease: "power2.inOut"
                })
                gsap.to('#range-container', {
                    opacity: 1,
                    duration: 0.2,
                    delay: 0.4,
                    ease: "power2.inOut"
                })
                gsap.to(headingRef.current, {
                    translateY: "0",
                    opacity: 1,
                    duration: 0.35,
                    delay: 0.2,
                    ease: "back.out"
                })
                if(isDesktop) {
                    // gsap.to(headingRef.current, {
                    //     translateY: "0",
                    //     opacity: 1,
                    //     duration: 0.35,
                    //     delay: 0.2,
                    //     ease: "back.out"
                    // })
                    if(windowSize.height < 850) {
                        gsap.to(headingRef.current, {
                            translateY: "-200px",
                            duration: 0.2,
                            delay: 1,
                            ease:  "power2.inOut"
                        })
                    }
                }
            },
            onLeaveBack: () => {
                setBlobVideoSource(BandVideo)
                setTheme({
                    palette: initPalette
                })
                gsap.to(IconWrapperRef.current, {
                    opacity: 0,
                    duration: 0,
                    ease: "none"
                })
            },
            onLeave: () => {
                setBlobVideoSource(BandVideo)
                setTheme({
                    palette: initPalette
                })
                gsap.to(IconWrapperRef.current, {
                    opacity: 0,
                    duration: 0,
                    ease: "none"
                })
                if(isDesktop && windowSize.height >= 850 || !isDesktop) {
                    gsap.to(headingRef.current, {
                        translateY: "-200px",
                        duration: 0.2,
                        delay: 0.1,
                        ease:  "power1.inOut"
                    })
                }
                gsap.to('#service-wrapper', {
                    translateY: "-200px",
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.1,
                    ease: "power1.inOut"
                })
                gsap.to('#range-wrapper', {
                    translateY: "-150px",
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.1,
                    ease: "power1.inOut"
                    // ease: "none"
                })
            },
            onEnterBack: () => {
                setTheme({
                    palette: {
                        'primary': services[services.length - 1].areaColorBackground?.hex,
                        'secondary': initPalette.secondary,
                        'tertiary': initPalette.tertiary
                    }
                })
                setBlobVideoSource(services[services.length - 1].videoFile.url)
                // console.log('on enter back');
                // setBlobVideoSource(services[services.length - 1].videoFile.url)
                gsap.to(IconWrapperRef.current, {
                    opacity: 1,
                    duration: 0,
                    ease: "none"
                })
                gsap.to('#range-wrapper', {
                    translateY: "0",
                    opacity: 1,
                    duration: 0.4,
                    ease: "power1.inOut"
                })
                gsap.to('#service-wrapper', {
                    translateY: "0",
                    opacity: 1,
                    duration: 0.4,
                    delay: 0.2,
                    ease: "power1.inOut"
                })
                if(isDesktop && windowSize.height >= 850 || !isDesktop) {
                    gsap.to(headingRef.current, {
                        translateY: "0",
                        duration: 0.4,
                        delay: 0.6,
                        ease: "power1.inOut"
                    })
                }
            }
        })

        services.forEach((item, index) => {
            const startOffset= (index / services.length) * 90
            ScrollTrigger.create({
                trigger: areasOfExpertiseRef.current,
                start: `top+=${startOffset}% top`,
                endTrigger: areasOfExpertiseRef.current,
                invalidateOnRefresh: true,
                // markers: true,
                onEnter: () => {
                    // console.log('enter', index);
                    if(index === 0) {
                        gsap.to(IconWrapperRef.current, {
                            opacity: 1,
                            duration: 0,
                            ease: "none"
                        })
                        // setTheme({palette: {
                        //         'primary': item.areaColorBackground?.hex,
                        //         'secondary':initPalette.secondary,
                        //         'tertiary': initPalette.tertiary
                        //     }
                        // })
                    }
                    setActiveItem({...services[index], id: index})
                    setBlobVideoSource(services[index].videoFile.url)
                    // setTimeout(() => {
                    //     setTheme({palette: {
                    //             'primary': item.areaColorBackground?.hex,
                    //             'secondary':initPalette.secondary,
                    //             'tertiary': initPalette.tertiary
                    //         }
                    //     })
                    // },500)

                    setTimeout(() => {
                        setTheme({palette: {
                                'primary': item.areaColorBackground?.hex,
                                'secondary':initPalette.secondary,
                                'tertiary': initPalette.tertiary
                            }
                        })
                    },70)
                },
                onLeaveBack: () => {
                    // console.log('leave back', index);
                    if (index === 0) {
                        setActiveItem({...services[0], backgroundColor: null, id: -1})
                        setBlobVideoSource(BandVideo)
                        setTheme({
                            palette: initPalette
                        })
                        gsap.to(IconWrapperRef.current, {
                            opacity: 0,
                            duration: 0,
                            ease: "none"
                        })
                        gsap.to('#service-container', {
                            opacity: 0,
                            duration: 0.2,
                        })
                        gsap.to('#range-container', {
                            opacity: 0,
                            duration: 0.2,
                        })
                        gsap.to(headingRef.current, {
                            translateY: "100px",
                            opacity: 0,
                            duration: 0.25,
                            delay: 0,
                            ease:  "power3.inOut"
                        })
                        if(isDesktop) {
                            if(windowSize.height < 850) {
                                gsap.to(headingRef.current, {
                                    opacity: 0,
                                    duration: 0,
                                    delay: 0,
                                })
                            }
                            // gsap.to(headingRef.current, {
                            //     translateY: "100px",
                            //     opacity: 0,
                            //     duration: 0.25,
                            //     delay: 0,
                            //     ease:  "power3.inOut"
                            // })
                        }
                    } else {
                        setActiveItem({...services[index-1], id: index - 1})
                        setBlobVideoSource(services[index-1].videoFile.url)
                        // setTimeout(() => {
                        //     setTheme({
                        //         palette: {
                        //             'primary': services[index - 1].areaColorBackground?.hex,
                        //             'secondary': initPalette.secondary,
                        //             'tertiary': initPalette.tertiary
                        //         }
                        //     })
                        // },500)

                        setTheme({
                            palette: {
                                'primary': services[index - 1].areaColorBackground?.hex,
                                'secondary': initPalette.secondary,
                                'tertiary': initPalette.tertiary
                            }
                        })
                    }
                },
                onLeave: () => {
                    // console.log('leave', index);
                    if(index === services.length - 1) {
                            gsap.to(IconWrapperRef.current, {
                                opacity: 0,
                                duration: 0,
                                ease: "none"
                            })
                        setBlobVideoSource(BandVideo);
                        setTheme({
                            palette: initPalette
                        })
                    }
                },
            })
        })
    },{ scope: areasOfExpertiseRef, dependencies: [windowSize.width], revertOnUpdate: true })

    // MOBILE LOGIC
    const Step1ProgressRef = useRef(0)
    const context = useMemo(() => ({
        step1: Step1ProgressRef,
        lensVisible: true,
    }), [])
    const updateContext = useCallback(({ progress }) => {
        context.step1.current = progress
    },[context, isDesktop])
    const [isMobileBlobVisible, setIsMobileBlobVisible] = useState(false);
    const isTablet = useIsTablet();
    const isMobile = useIsMobile()
    const scroll = useScrollContext()
    const loaded = useLoadedManager()

    useGSAP(() => {
        if(!isDesktop){
            ScrollTrigger.create({
                id: `pin-blob-${ID}`,
                // trigger: BlobRef.current,
                trigger: BlobRef.current,
                endTrigger: areasOfExpertiseRef.current,
                // start: 'top top+=21%',
                // start: isTablet ? 'center center-=50px' : 'center center-=100px',
                start: isTablet && orientationLandscape ? 'center center' : isTablet && !orientationLandscape ? 'center center-=50px' : 'center center-=100px',
                // start: 'center center',
                end: 'bottom bottom',
                pin: true,
                // markers: true,
                onEnter: () => {
                  setIsMobileBlobVisible(true)
                },
                onEnterBack: () => {
                    setIsMobileBlobVisible(true)
                },
                onLeave: () => {
                    setIsMobileBlobVisible(false)
                },
                onUpdate: () => {
                    setCssVars(BlobRef.current, { 'pg-step1': context.step1.current })
                }
            })
        }
    }, {
        dependencies: [isDesktop, orientationLandscape, windowSize.width],
        revertOnUpdate: true,
    })
    const handleServiceClick = (e) => {
        const { target } = e
        const isTag = target.classList.contains('tag')
        if (isTag) return
        return router.push(`/services/${serviceSlug}`)
    }

    // const handleTagClick = (e, slug) => {
    //     e.stopPropagation();
    //     router.push(slug)
    // }

    const serviceNameWrapperRef = useRef(null)
    const serviceNameWrapperLeftBound = useRef(0)


    useEffect(() => {
        if (!serviceNameWrapperRef.current) return
        const offsetLeft = serviceNameWrapperRef.current.offsetLeft
        serviceNameWrapperLeftBound.current = offsetLeft
    }, [serviceNameWrapperRef.current, windowSize.width]);


    //scroll to section if id is in url
    useEffect(() => {
        const { webgl, html } = loaded
        if(!webgl || !html) return
        const hash = router.asPath.split('#')[1]; //find id
        if(!hash) return
        //findIndex of service to scroll to
        const serviceIndex = services.findIndex((service) => service.slug === hash)
        if(serviceIndex < 0) return
        const height = (windowSize?.height || 0) * 2.75
        //calculate value to add to top of wrapper
        let offset = serviceIndex * height
        if(offset === 0){
            //if its first service increment offset to make sure it triggers animation
            offset += 1
        }
        //top of wrapper + estimated section top pos
        const final = areasOfExpertiseRef.current.getBoundingClientRect().top + offset

        // logTable({
        //     target: final,
        //     offset,
        //     serviceIndex,
        //     height
        // })

        //scroll.instance.scrollTo not working on mobile
        window.scroll({
            top: final,
            left: 0,
            behavior: 'instant'
        })
        // console.log('scroll');

    }, [router.asPath, loaded]);



    return (
        <Wrapper>
            <StyledAreasOfExpertiseContainer
                ref={areasOfExpertiseRef}
                bgColor={areaColorBackground}
                // $height={services.length * (isDesktop ? 150 : 300)}
                $height={services.length * 300}
                $backgroundColor={activeItem?.areaColorBackground?.hex}
            >
                <StyledAreasOfExpertisePinnedWrapper ref={pinnedWrapperRef}>
                    {/*<StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(heading)}/>*/}
                    <StyledSingleServiceWrapper
                        onClick={handleServiceClick}
                        data-cursor={CURSOR_TYPES.CHECK}
                        bgColor={areaColorBackground}
                    >
                        {/*<HalfLine/>*/}
                        <StyledHeader ref={headingRef} dangerouslySetInnerHTML={getHtmlFromRedactorField(heading)}/>
                        <ServiceNameContainer id='service-container'>
                            <AnimatePresence mode="wait">
                                <StyledServiceNameWrapper
                                    id="service-wrapper"
                                    key={activeItem?.id || ''}
                                    initial={{y: '100%'}}
                                    animate={{y: 0}}
                                    exit={{y: '-100%'}}>
                                    <ServiceIndex ref={serviceNameWrapperRef}>{`0${activeItem.id + 1}`}</ServiceIndex>
                                    <ServiceName>
                                        {serviceName}
                                    </ServiceName>
                                </StyledServiceNameWrapper>
                            </AnimatePresence>
                            {!!(isMobile || isTablet) && (
                                <AnimatePresence mode="wait">
                                    <StyledServiceNameWrapper
                                        id="service-wrapper"
                                        key={activeItem?.id || ''}
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}}>
                                        <ServiceDescription $paddingLeft={serviceNameWrapperLeftBound.current}>
                                            <div dangerouslySetInnerHTML={getHtmlFromRedactorField(serviceDescription)} />
                                        </ServiceDescription>
                                    </StyledServiceNameWrapper>
                                </AnimatePresence>
                            )}
                        </ServiceNameContainer>
                        <StyledVideoMockupForMobile/>
                        <StyledVideoWrapper className={isMobileDevice ? 'mobile-device' : ''}
                                            data-cursor={CURSOR_TYPES.CHECK}>
                            <StyledIconWrapper
                                rotationMultiplier={activeItem.id}
                                ref={IconWrapperRef}
                            >
                                <Icon className='areas-of-expertise-delay' name={ICONS.AREAS_OF_EXPERTISE_PIECE} color={borderColor}/>
                            </StyledIconWrapper>
                            {/*<PortalLens*/}
                            {/*    visible*/}
                            {/*    mobile={{*/}
                            {/*        ref: BlobRef,*/}
                            {/*        // visible: isMobileBlobVisible,*/}
                            {/*        visible: true,*/}
                            {/*        autoplay: true, // zeby film leciał*/}
                            {/*        hasWhiteBand: true,*/}
                            {/*        blobVideoSrc: blobVideoSource,*/}
                            {/*        // className: 'expertise'*/}
                            {/*        className: ID*/}
                            {/*    }}*/}
                            {/*    context={{*/}
                            {/*        // src: videoFile*/}
                            {/*        src: blobVideoSource,*/}
                            {/*        blobScale: blobScale*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <BlobPortal/>*/}
                            {/*</PortalLens>*/}
                            {/*{videoFile && (<video*/}
                            {/*    key={videoFile}*/}
                            {/*    preload="auto"*/}
                            {/*    muted*/}
                            {/*    loop*/}
                            {/*    autoPlay={true}*/}
                            {/*    playsInline*/}
                            {/*>*/}
                            {/*    <source src={videoFile} type="video/mp4"/>*/}
                            {/*</video>)}*/}
                            {/*{externalVideoUrl && <Video src={externalVideoUrl}/>}*/}
                            {/*{externalVideoUrl &&(*/}
                            {/*    <StyledReactPlayerWrapper externalVideoAspectRatio={externalVideoAspectRatio}>*/}
                            {/*        <ReactPlayer*/}
                            {/*            url={externalVideoUrl}*/}
                            {/*            volume={0}*/}
                            {/*            // width="100%"*/}
                            {/*            // height="100%"*/}
                            {/*            wrapper="figure"*/}
                            {/*            muted*/}
                            {/*            loop*/}
                            {/*            playsinline={true}*/}
                            {/*            playing={true}*/}
                            {/*            // playing={false}*/}
                            {/*            // fallback={<Fallback/>}*/}
                            {/*            controls={false}*/}
                            {/*            // onPause={() => PlayerRef.current.seekTo(0)}*/}
                            {/*        />*/}
                            {/*    </StyledReactPlayerWrapper>*/}
                            {/*)}*/}
                        </StyledVideoWrapper>
                        <StyledRangeContainer id='range-container'>
                            <AnimatePresence mode="wait">
                                <StyledRangeWrapper id="range-wrapper"
                                                    key={activeItem?.id || ''}
                                                    initial={{y: '120%'}}
                                                    animate={{y: 0}}
                                                    transition={{delay: 0}}
                                                    exit={{y: '-120%'}}>
                                    <RangeText>{rangeText}</RangeText>
                                    <StyledCompetencesTagWrapper>
                                        {competences.map((item, index) => {
                                            const pathWithNoHash = router.asPath.split('#')[0];
                                            const href = `/competence/${item.slug}?origin=${pathWithNoHash}-id-${activeItem?.slug || ''}`
                                            return (
                                                    <Tag
                                                        key={item?.competenceName || index}
                                                        href={href}
                                                        accentColor={borderColor}
                                                    >
                                                        {item.competenceName}
                                                    </Tag>
                                            )
                                        })}
                                    </StyledCompetencesTagWrapper>
                                    {competences.length > 5 && <MoreTags accentColor={borderColor}>+ more</MoreTags>}
                                    {/*<RevealWrapper>*/}
                                    <ButtonWrapper
                                        className={isMobileDevice && orientationLandscape ? 'mobile-device-landscape' : ''}
                                    >
                                        <Button
                                            as="link"
                                            href={`/services/${serviceSlug}`}
                                            variant="secondary">
                                            {buttonText}
                                        </Button>
                                    </ButtonWrapper>
                                    {/*</RevealWrapper>*/}
                                </StyledRangeWrapper>
                            </AnimatePresence>
                        </StyledRangeContainer>
                    </StyledSingleServiceWrapper>
                </StyledAreasOfExpertisePinnedWrapper>
                {/*<div>*/}
                {/*    <PortalLens*/}
                {/*        visible*/}
                {/*        mobile={{*/}
                {/*            ref: BlobRef,*/}
                {/*            // visible: isMobileBlobVisible,*/}
                {/*            visible: true,*/}
                {/*            autoplay: true, // zeby film leciał*/}
                {/*            hasWhiteBand: true,*/}
                {/*            blobVideoSrc: blobVideoSource,*/}
                {/*            // className: 'expertise'*/}
                {/*            className: ID*/}
                {/*        }}*/}
                {/*        context={{*/}
                {/*            // src: videoFile*/}
                {/*            src: blobVideoSource,*/}
                {/*            blobScale: blobScale*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <BlobPortal/>*/}
                {/*    </PortalLens>*/}
                {/*</div>*/}
                {/*<Transition id={`${ID}-step1`} onUpdate={updateContext}/>*/}
            </StyledAreasOfExpertiseContainer>
            <div>
                <PortalLens
                    visible
                    mobile={{
                        ref: BlobRef,
                        // visible: isMobileBlobVisible,
                        visible: true,
                        autoplay: true, // zeby film leciał
                        hasWhiteBand: true,
                        blobVideoSrc: blobVideoSource,
                        // className: 'expertise'
                        className: ID
                    }}
                    context={{
                        // src: videoFile
                        ...context,
                        src: blobVideoSource,
                        blobScale: blobScale
                    }}
                >
                    <BlobPortal/>
                </PortalLens>
            </div>
            <Transition className='under-content' id={`${ID}-step1`} start="top" onUpdate={updateContext}/>
        </Wrapper>
    );
};


export default AreasOfExpertise;
