import { useMemo } from "react";
import styled from "styled-components";
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";

const StyledWrapper = styled.div`
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 58%;
    min-width: 0;
    flex: 0 0 100%;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        flex: 0 0 fit-content;
        height: 70%;
        margin: 0 25px;
        min-width: unset;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: 75%;
        margin: 0 25px;
    }
`

const StyledCardWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
    width: auto;
    aspect-ratio: 640 / 850;
    padding: ${({$isText}) => $isText ? getCssSizeValue(12, BREAKPOINTS.MOBILE) : 0};
    
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding: ${({$isText}) => $isText ? getCssSizeValue(23, BREAKPOINTS.TABLET) : 0};
        background: ${theme.colors.white};
        transform: translateX(100vw);
    }
`

const StyledText = styled.div`
    user-select: none;
    color: ${theme.colors.twilightBlue};
    font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(28, BREAKPOINTS.TABLET)};
    font-style: italic;
    letter-spacing: -1.12px;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(34, BREAKPOINTS.TABLET)};
    }
    
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(35, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(47, BREAKPOINTS.DESKTOP)};
        letter-spacing: -1.92px;
    }
`

const StyledImage = styled.img`
    height: 100%;
    width: auto;
    aspect-ratio: 640 / 850;
`

export default function SimpleCard({ item, isExpanded, ...props }) {
    const text = item?.text || ''
    const imageUrl = item?.image?.url || ''
    const imageAlt = item?.image?.alt || ''
    const isTextCard = useMemo(() => !!text, [item])

    return (
        <StyledWrapper {...props}>
            <StyledCardWrapper $isText={isTextCard} className={`${isExpanded ? 'expanded' : ''} subCarouselSimpleCard`}>
                {isTextCard ? (
                    <StyledText dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
                ) : (
                    <StyledImage src={imageUrl} alt={imageAlt} />
                )}
            </StyledCardWrapper>
        </StyledWrapper>
    )
}
