import {useRef} from "react";
import {gsap, useGSAP} from "@repo/utils/gsap";
import {theme} from "../../styles/themeSettings";

const BASE_DURATION = 0.2;
const DURATION_FACTOR = 1000;

function normalizeDuration(maxSize) {
    return Math.min(1, Math.max(BASE_DURATION, maxSize / DURATION_FACTOR));
}

const useExpandBackgroundAnimation = (ref) => {
    if (!ref) return

    const tl = useRef(gsap.timeline({paused: true}));

    useGSAP(() => {
        const mm = gsap.matchMedia()

        mm.add({
            mobile: `(max-width:${theme.breakpoints.tablet - 1}px)`,
            tablet: `(min-width:${theme.breakpoints.tablet}px) and (max-width: ${theme.breakpoints.desktop}px)`,
            desktop: `(min-width:${theme.breakpoints.desktop}px)`,
        }, (ctx) => {
            const {mobile, tablet, desktop} = ctx.conditions

            const {height, width} = ref.current.getBoundingClientRect()

            const maxSize = Math.max(width, height);
            const scaleFactor = desktop ? 4 : tablet ? 2 : mobile ? 6 : 3;
            const finalSize = maxSize * scaleFactor;

            const normalizedDuration = normalizeDuration(maxSize);

            tl.current.to(ref.current, {
                '--width': `${finalSize}px`,
                '--height': `${finalSize}px`,
                ease: 'none',
                duration: normalizedDuration
            });
        })
    });

    return tl;
};

export default useExpandBackgroundAnimation;