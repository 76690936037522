import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getCssSizeValue, getFontSize, getIdWithoutSpecialSymbols, getPaddingsClassFromData} from "@repo/utils";
import {BREAKPOINTS, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import {Button, useIsDesktop, useScrollContext} from "../../../index";
import CommonImage from '../common/Image';
import NewsThumbnailCarousel from "./NewsThumbnailsCarousel";
import {useRouter} from "next/router";
import DateInfo from "../common/DateInfo";
import SingleArticleCard from "./SingleArticleCard";
import {getButtonBlockData} from "../common/Button";
import Modal from "../Modal/Modal";
import GridLinesBackground from "../DescriptionLinePleo/GridLinesBackground";

const RelativeWrapper = styled.div`
	position: relative;
`

const NewsThumbmailsWrapper = styled.div`
    width: 100%;
    position: relative;
    color: var(--secondary-color);
`

const HeaderAndButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const Header = styled.p`
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
    }
`

const ThumbnailsContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${getCssSizeValue(47, BREAKPOINTS.DESKTOP)};
    margin-top: ${getCssSizeValue(110, BREAKPOINTS.DESKTOP)};

    &.mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: ${getCssSizeValue(110, BREAKPOINTS.MOBILE)};
    }
`

const SingleThumbnailWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    p.read-time {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(20, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
        }
    }

    p.title-mobile {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
            margin-bottom: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
        }
    }

    p.title {
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
            margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.DESKTOP)};
        }
    }

    &.mobile {
        flex-direction: row;
        column-gap: ${getCssSizeValue(29, BREAKPOINTS.MOBILE)};
        margin-bottom: 25px;
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            column-gap: ${getCssSizeValue(38, BREAKPOINTS.TABLET)};
        }
    }
`

const SingleCard = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    aspect-ratio: ${({aspectRatio}) => aspectRatio ? aspectRatio : 16 / 9};

    &.mobile {
        width: ${getCssSizeValue(129, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            width: ${getCssSizeValue(174, BREAKPOINTS.TABLET)};
        }
    }
`

const StyledPleoTag = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 106px;
    height: 33px;
    z-index: 2;
    border-radius: 0 0 0 13px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
            margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.DESKTOP)};
        }
    }

    &.pleo-digital {
        background-color: #E84F44;
    }

    &.pleo-design {
        background-color: #2AC1D7;
    }

    &.pleo-method {
        background-color: #9747FF;
    }

    &.pleo-group {
        background-color: #1a303c;
    }
`


const Image = styled(CommonImage)`
    overflow: hidden;
    position: relative;
`

const CategoriesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 10px;

    p.category {
        text-transform: uppercase;
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        }
    }

    &.mobile {
        margin: 0;
        flex-wrap: wrap;

        p.category {
            text-transform: uppercase;
            color: #A6A6A6;
            font-size: ${getFontSize(10, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(14, BREAKPOINTS.MOBILE)};
            font-weight: ${({theme}) => theme.fontWeight.regular};
            @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
                font-size: ${getFontSize(10, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
            }
        }
    }
`

const StyledCardWithTextsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    &.mobile {
        justify-content: space-between;
        width: calc(100% - ${getCssSizeValue(129, BREAKPOINTS.MOBILE)} - ${getCssSizeValue(29, BREAKPOINTS.MOBILE)});
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            width: calc(100% - ${getCssSizeValue(174, BREAKPOINTS.TABLET)} - ${getCssSizeValue(38, BREAKPOINTS.TABLET)});
        }
    }
`

const StyledReadTimeAndDateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        margin-top: ${getCssSizeValue(18, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        margin-top: ${getCssSizeValue(18, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
    }

    p {
        line-height: normal !important;
    }
`

const NewsThumbnails = ({data, globals, listOnMobile, isCurrentPageGroup, hidePleoTags}) => {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const {push, locale} = useRouter();
    const header = data?.header || '';
    const buttonData = getButtonBlockData(data)
    const news = data?.chooseNews || [];
    const paddings = getPaddingsClassFromData(data)
    const readArticleButtonText = globals?.readArticleButtonText || '';
    const isDesktop = useIsDesktop();
    const readingTimeText = locale === 'pl' ? 'przeczytasz w' : 'read';
    const scroll = useScrollContext()
    const modalHeader = globals?.modalHeader || ''
    const modalDescription = globals?.modalDescription || '';
    const modalButtonTextClose = globals?.buttonTextClose || '';
    const modalButtonTextOpen = globals?.buttonTextOpen || '';
    const displayGridLines = data?.displayGridLines || false
    const gridLinesEndModuleId = data?.gridLinesEndModule?.id || ''

    const handleClickBlogPage = (slug) => {
        if (!isCurrentPageGroup) {
            setExternalUrl(`${process.env.PLEO_GROUP_URL}${locale === 'pl' ? '/pl/' : '/'}news`)
            setIsModalVisible(true)
        } else {
            push(`/${slug}`);
        }
    }
    const handleOnClick = (slug) => {
        if (!isCurrentPageGroup) {
            setExternalUrl(`${process.env.PLEO_GROUP_URL}${locale === 'pl' ? '/pl/' : '/'}news/${slug}`)
            setIsModalVisible(true)
        } else {
            push(`/news/${slug}`);
        }
    };

    useEffect(() => {
        scroll.collectComponents()
    }, [isDesktop, listOnMobile]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [externalUrl, setExternalUrl] = useState('')
    return (
        <>
            <Modal
                isVisible={isModalVisible}
                handleCloseButton={() => setIsModalVisible(false)}
                data={{
                    header: modalHeader,
                    text: modalDescription,
                    buttonText: modalButtonTextClose,
                    buttonTextOpen: modalButtonTextOpen,
                    externalUrl: externalUrl
                }}
            />
            {isDesktop && <NewsThumbmailsWrapper
                className={`${paddings} px-main`}
                id={id}
                data-color-scheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}
            >
                <HeaderAndButtonWrapper>
                    <Header>{header}</Header>
                    {buttonData.exists &&
                        <Button onClick={() => handleClickBlogPage(buttonData.link)}>{buttonData.text}</Button>}
                </HeaderAndButtonWrapper>
                <ThumbnailsContainer>
                    {displayGridLines && <GridLinesBackground gridLinesEndModuleId={gridLinesEndModuleId} />}
                    {news.map((item) => {
                        const title = item?.blogPageName || '';
                        const slug = item?.slug || '';
                        return (
                            <SingleArticleCard
                                key={title}
                                data={item}
                                globals={globals}
                                locale={locale}
                                hidePleoTags={hidePleoTags}
                                buttonVariant="primary"
                                isCurrentPageGroup={isCurrentPageGroup}
                                handleClick={() => handleOnClick(slug)}
                            />
                        )
                    })}
                </ThumbnailsContainer>
            </NewsThumbmailsWrapper>}
            {(!isDesktop && !listOnMobile) &&
                <NewsThumbnailCarousel
                    colorScheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}
                    locale={locale}
                    data={data}
                    hidePleoTags={hidePleoTags} handleClick={handleOnClick}
                    handleClickBlogPage={handleClickBlogPage}
                    displayGridLines={displayGridLines}
                    gridLinesEndModuleId={gridLinesEndModuleId}
                />}
            {(!isDesktop && listOnMobile) && (
                <NewsThumbmailsWrapper
                    className={`${paddings} px-main`}
                    data-color-scheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}>
                    <HeaderAndButtonWrapper>
                        <Header>{header}</Header>
                    </HeaderAndButtonWrapper>
                    <ThumbnailsContainer className="mobile">
                        {news.map((item) => {
                            const title = item?.blogPageName || '';
                            const slug = item?.slug || '';
                            const wordsCounter = item?.wordsCount?.words || 1;
                            const wordsPerMinute = 200;
                            const readingTimeInMinutes = Math.ceil(wordsCounter / wordsPerMinute) || 1;
                            const assetUrl = item?.thumbnailImage?.url || '';
                            const assetWidth = item?.thumbnailImage?.width || 0;
                            const assetHeight = item?.thumbnailImage?.height || 1;
                            const aspectRatio = assetWidth / assetHeight === 0 ? '16/9' : assetWidth / assetHeight;
                            const pleoCategory = item?.pleoCategory || '';
                            const categories = item?.categories || [];
                            const categoriesWithoutDashes = categories.map((item) => item.replace(/-/g, ' '));
                            const readTimeFullText = locale === 'pl' ? `${readingTimeText} ${readingTimeInMinutes} min` : `${readingTimeInMinutes} min ${readingTimeText}`;
                            return (
                                <SingleThumbnailWrapper onClick={() => handleOnClick(slug)} className="mobile"
                                                        key={title}>
                                    <SingleCard className="mobile" aspectRatio={aspectRatio}>
                                        <Image aspectRatio={aspectRatio} src={assetUrl} sizes={"100%"}/>
                                    </SingleCard>
                                    <StyledCardWithTextsWrapper className="mobile">
                                        <CategoriesWrapper className="mobile">
                                            {categoriesWithoutDashes.map((item) => <p className="category"
                                                                                      key={item}>{item}</p>)}
                                        </CategoriesWrapper>
                                        <StyledReadTimeAndDateWrapper>
                                            <p className="read-time">{readTimeFullText}</p>
                                        </StyledReadTimeAndDateWrapper>
                                        <p className="title-mobile">{title}</p>
                                    </StyledCardWithTextsWrapper>
                                </SingleThumbnailWrapper>
                            )
                        })}
                    </ThumbnailsContainer>
                </NewsThumbmailsWrapper>
            )}
        </>
    );
};

export default NewsThumbnails;
