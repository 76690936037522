import React, {
    createContext,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {SmoothScrollbar, useScrollbar, useScrollRig} from '@14islands/r3f-scroll-rig';
import styled from 'styled-components';
import {ScrollTrigger} from "@repo/utils/gsap";
import {deepClone} from '@repo/utils';
import {DEFAULT_COLOR_SCHEME} from "../../styles/themeSettings";
import {useExtendedTheme} from "./Theme";
import {useIsDesktop, useWindowSize} from "../../index";
import useCustomContext from "../hooks/useCustomContext";
import { useRouter } from 'next/router';

const Main = styled.main`
	position: relative;
	width: 100%;
	top: 0;
	left: 0;
`;

function createArrayOfComponentsByDataAttribute(name) {
    const attr = `data-${name}`
    return [...document.querySelectorAll(`[${attr}]`)].map(el => ({ el, positions: {}, type: name, value: el.getAttribute(attr) || ''  }))
}

const ScrollContext = createContext({
    components: [],
    instance: {},
    rig: {},
    refresh: (updatePosition=true) => {},
    collectComponents: () => {},
    main: null
});

const CHANGE_COLOR_SCHEME_BREAKPOINT = 100; //units of velocity
const DATA_COLOR_SCHEME_ATTRIBUTE = 'color-scheme'

const Scroll = ({ children, updateDependencies=[] }) => {
    const MainRef = useRef(null);
    const isDesktop = useIsDesktop();
    const scrollRig = useScrollRig()
    const windowSize = useWindowSize()
    const scrollbar = useScrollbar();
    const extendedTheme = useExtendedTheme();
    const [cs, setCs] = useState(DEFAULT_COLOR_SCHEME);
    const pageComponents = useRef([])
    const router = useRouter()
    // const transitionCompleted = usePageTransitionContext((state) => state.transitionCompleted)
    const updateDeps =  Array.isArray(updateDependencies) ? updateDependencies : []

    // function to calculate element position
    const setPosition = useCallback((target) => {
        const currScroll =  scrollbar.scroll.y || 0;
        const positions = deepClone(target.el.getBoundingClientRect()) ;
        //add scroll value to keep consistent breakpoints
        positions.top += currScroll;
        positions.bottom += currScroll;
        positions.y += currScroll;
        target.positions = positions;
    },[scrollbar])

    const refresh = useCallback((updatePosition=true) => {
        if(updatePosition) pageComponents.current.forEach((component) => setPosition(component))
        scrollRig.reflow()
        ScrollTrigger.refresh(true)
    },[])

    const observerRef = useRef(new ResizeObserver((entries) => {
        refresh()
    }))

    const cleanup = useCallback(() => {
        pageComponents.current = []
        observerRef.current.disconnect();
    },[])


    const collectComponents = useCallback(() => {
        cleanup()
        const dataColorSchemeComponents = createArrayOfComponentsByDataAttribute(DATA_COLOR_SCHEME_ATTRIBUTE).filter((item) => item.value !== DEFAULT_COLOR_SCHEME);
        pageComponents.current = dataColorSchemeComponents
        pageComponents.current.forEach((component) => {
            observerRef.current.observe(component.el, { box: 'border-box' })
            setPosition(component)
        })
    },[])


    //toggle color scheme on scroll
    const handleCsOnScroll = useCallback((lenis) => {
        const { scroll, dimensions, velocity } = lenis;
        const halfScreen = scroll + dimensions.height / 2;
        const isVelocityLowEnough = Math.abs(velocity) < CHANGE_COLOR_SCHEME_BREAKPOINT;

        if(!isVelocityLowEnough) return
        const csElement = pageComponents.current.find((element) => {
            if(element.type !== DATA_COLOR_SCHEME_ATTRIBUTE) return null
            const {top, bottom} = element.positions;
            const isInMiddleOfViewport = top < halfScreen && halfScreen < bottom;
            if (isInMiddleOfViewport) return element;
            return null;

        })
        const cs = csElement?.value || DEFAULT_COLOR_SCHEME
        setCs(cs);
    },[])


    useEffect(() => {
        return () => {
            scrollbar.scrollTo(0, { immediate: true, force: true })
        }
    },[router.asPath])

    useEffect(() => {
        collectComponents()

        return () => {
            cleanup()
        };
    }, [isDesktop, ...updateDeps]); //some components conditionally render on isDesktop

    useEffect(() => {
        refresh()
        //refresh also on theme.mode update
        //refreshing on colorScheme change caused issues
    },[windowSize.width, extendedTheme.mode])


    useEffect(() => {
        return scrollbar.onScroll((e) => {
            handleCsOnScroll(e);
        });
    }, [scrollbar]);

    useEffect(() => {
        extendedTheme.setTheme({ colorScheme: cs });
    }, [cs]);

    const context = useMemo(() => ({
        components: pageComponents,
        instance: scrollbar,
        rig: scrollRig,
        refresh,
        main: MainRef.current,
        collectComponents
    }), [collectComponents,scrollbar,scrollRig, refresh]);


    return (
        <ScrollContext.Provider value={context}>
            <SmoothScrollbar
                //forcing re-init fixes page speed report break, check also in GlobalCanvas
                //no it doesn't
                // key={isDesktop}
                enabled={isDesktop}
                scrollRestoration="manual"
            />
            <Main id="main" ref={MainRef} key="main">
                {children}
            </Main>
        </ScrollContext.Provider>
    );
};

export function useScrollContext(selector) {
    return useCustomContext(ScrollContext,selector);
}

export default memo(Scroll);
