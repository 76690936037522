import {BREAKPOINTS, DEFAULT_COLOR_SCHEME, theme} from '../ui/styles/themeSettings';
import {CONTENT_TYPES, PADDING} from "./constants";



//applies variables to the element or an array of elements
//e.x. setCssVars(SomeRef.current, { color: 'red', opacity: 1 })
//e.x. setCssVars([FirstRef.current, SecondRef.current], { color: 'red', opacity: 1 })
//effect: --color: red, --opacity: 1
export function setCssVars(target, vars = {}) {
    if (!target || typeof vars !== 'object' || Object.keys(vars).length <= 0) return;
    function setter(domNode, object) {
        return Object.keys(object).forEach((key) => {
            domNode?.style.setProperty(`--${key}`, object[key]);
        });
    }
    if (Array.isArray(target)) return target.forEach((arrEl) => setter(arrEl, vars));
    return setter(target, vars);
}

//handles CMS redactor fields
export function getHtmlFromRedactorField(field) {
    if (!field) {
        return null;
    }
    return { __html: `${field}` };
}

//returns deep cloned object to prevent unintentional mutations
export function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
        throw new Error('Object is not an object type');
    }
    return JSON.parse(JSON.stringify(obj));
}

//returns interpolated value between a & b with a delta of n
export function lerp(a, b, n) {
    return (1 - n) * a + n * b;
}

//returns random value in the given range
export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}


export function handleRef(ref, source) {
    if(Array.isArray(ref)){
        ref.forEach((r) => {
            if (typeof r === 'function') r(source);
            else if (r) r.current = source;
        })
    } else {
        if (typeof ref === 'function') ref(source);
        else if (ref) ref.current = source;
    }

}
function removeTrailingZeros(number, precision=4){
    return parseFloat((number).toFixed(precision))
}
//return css function for scalable elements
const BREAKPOINT_WIDTHS = {
    [BREAKPOINTS.LARGE_DESKTOP]: 2560,
    [BREAKPOINTS.DESKTOP]: 1920,
    [BREAKPOINTS.TABLET]: 768,
    [BREAKPOINTS.MOBILE]: 390,
};

export function getCssSizeValue(value = 20, breakpoint = BREAKPOINTS.DESKTOP) {
    if (Number.isNaN(value)) throw new Error('Value is not a number');
    const designWidth = BREAKPOINT_WIDTHS[breakpoint] || BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP];
    //return rem values for tablet nad mobile
    if(designWidth < BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP]){
        return `${removeTrailingZeros(value/16)}rem`;
    }
    const breakpointWidth = theme.breakpoints[breakpoint]
    const aspect = breakpointWidth/designWidth
    const scaledValue = value * aspect;
    const scaledRem = removeTrailingZeros(scaledValue/16);
    const responsiveValue = removeTrailingZeros((scaledValue/breakpointWidth) * 100)
    return `max(${responsiveValue}vw, ${scaledRem}rem)`;
}

// If design widths match breakpoints width use this version
// export function getCssSizeValue(value = 20, breakpoint = BREAKPOINTS.DESKTOP) {
//     if (!value) throw new Error('Value is not defined');
//     if (typeof value !== 'number') throw new Error('Value is not a number');
//     const width = BREAKPOINT_WIDTHS[breakpoint] || BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP];
//     const rem = removeTrailingZeros(value/16);
//     //return rem values for tablet nad mobile
//     if(width < BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP]){
//         return `${rem}rem`;
//     }
//     const responsiveValue = removeTrailingZeros((value / width) * 100)
//     return `max(${responsiveValue}vw, ${rem}rem)`;
// }

// Old version
// export function getCssSizeValue(value = 20, breakpoint = BREAKPOINTS.DESKTOP) {
//     if (!value) throw new Error('Value is not defined');
//     if (typeof value !== 'number') throw new Error('Value is not a number');
//     const width = BREAKPOINT_WIDTHS[breakpoint] || BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP];
//     const rem = removeTrailingZeros(value/16);
//     if(width < BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP]){
//         return `${rem}rem`;
//     }
//     const responsiveValue = removeTrailingZeros((value / width) * 100)
//     return `min(${responsiveValue}vw, ${rem}rem)`;
// }

export function getOldCssSizeValue(value = 20, breakpoint = BREAKPOINTS.DESKTOP) {
    if (!value) throw new Error('Value is not defined');
    if (typeof value !== 'number') throw new Error('Value is not a number');
    const width = BREAKPOINT_WIDTHS[breakpoint] || BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP];
    const rem = removeTrailingZeros(value/16);
    if(width < BREAKPOINT_WIDTHS[BREAKPOINTS.DESKTOP]){
        return `${rem}rem`;
    }
    const responsiveValue = removeTrailingZeros((value / width) * 100)
    return `min(${responsiveValue}vw, ${rem}rem)`;
}



export function getFontSize(value = 20, breakpoint = BREAKPOINTS.DESKTOP) {
    const css = getCssSizeValue(value, breakpoint);
    return `calc(${css} * var(--typo-size, 1))`;
}

export function getIdWithoutSpecialSymbols (id) {
    return id ? id.replace(/[^a-zA-Z ]/g, "") : '';
}

export function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function partition(array=[], filter) {
    return array.reduce(
        ([pass, fail], elem, index) => {
            return filter(elem, index) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
        },
        [[], []],
    );
}

export const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export function getStringRange(range=[], year=''){
    return [year, ...range.map((item) => item.serviceName)].filter((val) => Boolean(val)).toString().replaceAll(',', ' • ');
}
export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function clamp(value, floor, ceil) {
    return Math.min(Math.max(value, floor), ceil);
}

//returns relative progress of the given range
export function getRangeProgress(current, floor, ceil) {
    if (ceil <= floor) throw new Error('Ceiling should be greater than Floor!');
    const clampedCurrent = Math.min(Math.max(current, floor), ceil);
    return (clampedCurrent - floor) / (ceil - floor);
}

//go from side to side of the progress
//e.x. current = 0 return = 0, current = 0.5 return = 1, current = 1 return = 0
export function alternateProgress(current) {
    return 1 - 2 * Math.abs(current - 0.5);
}

export function inRange(current, min, max){
    return current >= min && current <= max;
}

export function getColorScheme(data){
    return data?.colorScheme?.colorScheme || DEFAULT_COLOR_SCHEME;
}

export function getPaddingClass(padding, side='top'){
    if(padding === PADDING.NONE) return ''
    const prefix = `p${side.slice(0,1)}`
    return padding ? `${prefix}-${padding}` : '';
}
export function getPaddingsClassFromData(data) {
    const paddingTop = data?.paddings?.top || PADDING.NONE;
    const paddingBottom = data?.paddings?.bottom || PADDING.NONE;
    const paddingTopClass = getPaddingClass(paddingTop, 'top')
    const paddingBottomClass = getPaddingClass(paddingBottom, 'bottom')
    return `${paddingTopClass} ${paddingBottomClass}`;
}

//removes tags from string
//as a second parameter pass array of html tags, e.x.: ['p','span']
export function removeTags(input, tags) {
    let output = input;
    tags.forEach((tag) => {
        const regex = new RegExp(`<\/?${tag}(\\s*\\/?)?>`, 'g');
        output = output.replace(regex, '');
    });
    return output;
}

export function replaceDataColorWithStyle(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const elements = doc.querySelectorAll('[data-color]');

    elements.forEach(el => {
        const color = el.getAttribute('data-color');
        el.style.color = color;
        el.removeAttribute('data-color');
    });

    return doc.body.innerHTML;
}

export function getAspectRatioFromImage(image) {
    const videoWidth = image?.width || 0;
    const videoHeight = image?.height || 1;
    return videoWidth / videoHeight === 0 ? '16/9' : videoWidth / videoHeight;
}

export function getPrefix(typename){
    switch (typename){
        case CONTENT_TYPES.COMPETENCES_PAGE_RECORD:
            return '/competence'
        case CONTENT_TYPES.PROJECT_TECHNOLOGY_RECORD:
            return '/tools-methods'
        case CONTENT_TYPES.SERVICE_PAGE_RECORD:
            return '/services'
        case CONTENT_TYPES.DIGITAL_SINGLE_PROJECT_RECORD:
            return '/projects'
        case CONTENT_TYPES.DESIGN_SINGLE_PROJECT_RECORD:
            return '/projects'
        default:
            return ''
    }
}

export function getSlug(slug){
    if(typeof slug !== 'string') throw new Error('slug is not string type')
    let updatedSlug = slug
    if (slug.includes('home')) updatedSlug = '/'
    return updatedSlug.trim()
}
export const mergeArrays = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}

export function isReactRef(obj) {
    return obj && typeof obj === 'object' && 'current' in obj;
}

export function isString(value) {
    return typeof value === 'string';
}
export function isFunction(value) {
    return typeof value === 'function';
}
export function isUndefined(value) {
    return typeof value === 'undefined';
}

export function logTable(object = {}) {
    const table = {}
    Object.keys(object).forEach((key) => {
        table[key] = object[key]
    })

    return console.table(table)
}

//useful for testing, pass variable in an object like this: { myVar: 0.1 }
//expected return: console.log('myVar:', 0.1)
export function logNamed(obj) {
    Object.entries(obj).forEach(([key, value]) => console.log(key + ":", value))
}

export function isStringEmpty(str) {
    return !str || str.trim().length === 0;
}

export function lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getMapKey(item, fallback){
    return item?.id || fallback
}

export function removeTrailingSlashes(input) {
    return input.replace(/\/+$/, '');
}

export function slugify(slug=''){
    if(!isString(slug)) {
        console.warn('slug is not a string!')
        return ''
    }
    let trimmedSlug = slug.trim()
    if(trimmedSlug === '/'){
        return trimmedSlug
    }
    return removeTrailingSlashes(trimmedSlug)
}
export function multiplyModifier(modifier, multiplier = 1.5) {
    let match = modifier.match(/(\+=|-=)?(\d+)%/);
    if (!match) {
        throw new Error("Invalid modifier format");
    }

    let [, operator = "", percentage] = match;
    let percentValue = parseFloat(percentage) * multiplier; // Multiply by given multiplier

    if (operator === "") {
        operator = "="; // Ensure standalone percentage remains valid
    }

    return operator + percentValue + "%";
}

export function precise(x, precision=5) {
    return parseFloat((x).toFixed(precision))
}

export function getPropSafely(data, variable, fallback){
    return data?.[variable] || fallback || ''
}

export const handleSetPositionOnMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    e.currentTarget.style.setProperty('--x', `${x}%`);
    e.currentTarget.style.setProperty('--y', `${y}%`);
};
