import {useEffect, useRef, useState} from "react";
import useIsDesktop from "./useIsDesktop";

const DEFAULT_VALUES = {
    onlyDesktop: false,
}

export default function useHover({ onlyDesktop = false } = DEFAULT_VALUES) {
    const isDesktop = useIsDesktop();
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef(null);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    useEffect(() => {
        if (onlyDesktop && !isDesktop) {
            return;
        }

        if (ref.current) {
            const controller = new AbortController();
            const { signal } = controller;

            ref.current.addEventListener("mouseenter", () => setIsHovered(true), { signal });
            ref.current.addEventListener("mouseleave", () => setIsHovered(false), { signal });

            return () => controller.abort();
        }
    }, [isDesktop, onlyDesktop]);

    return [ref, isHovered, setIsHovered];
}