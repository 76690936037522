import React, {useMemo, useState} from 'react';
import styled, {css} from 'styled-components';
import CommonTextWithBreaks from "../common/TextWithBreaks";
import CommonLink from "../common/Link";
import CommonIcon from "../common/Icon/Icon";
import Text from "../common/Text";
import { getCssSizeValue, getFontSize, getIdWithoutSpecialSymbols, mergeArrays} from "@repo/utils";
import {BREAKPOINTS, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import Reveal from "../../animations/Reveal";
import { delay } from "@repo/utils/gsap";
import {useRouter} from "next/router";


const Container = styled.div`
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    
    --padding-top: ${getCssSizeValue(64, BREAKPOINTS.MOBILE)};
    --padding-bottom: ${getCssSizeValue(90, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        //grid-template-columns: 1fr 1fr;
        grid-template-columns: 1.5fr 1fr;
        --padding-top: ${getCssSizeValue(64, BREAKPOINTS.TABLET)};
        --padding-bottom: ${getCssSizeValue(90, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        //grid-template-columns: 1.5fr 1fr;
        --padding-top: ${getCssSizeValue(85, BREAKPOINTS.DESKTOP)};
        --padding-bottom: ${getCssSizeValue(90, BREAKPOINTS.DESKTOP)};
    }
`

const Side = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    padding: var(--padding-top) var(--paddingX) var(--padding-bottom);
    transition: background var(--color-transition-duration) var(--ease);
    
    .tag{
        text-transform: lowercase;
        line-height: normal;
        font-size: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    }

    &.left {
        cursor: pointer;
        background-color: ${({theme}) => theme.colors.white};
        
        &.color-bg {
            background-color: ${({theme}) => theme.colors.red};
        }
    }
    &.right {
        background-color: ${({theme}) => theme.colors.lightGray};
        padding-bottom: ${getCssSizeValue(86, BREAKPOINTS.MOBILE)};
        //background: var(--primary-color);
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        .tag{
            font-size: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
        }
        &.right {
            padding-left: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
        }
        //justify-content: end;
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        .tag{
            font-size: ${getCssSizeValue(18, BREAKPOINTS.DESKTOP)};
        }
        &.right {
            padding-left: ${getCssSizeValue(90, BREAKPOINTS.DESKTOP)};
        }
    }
`
const TextWithBreaks = styled(CommonTextWithBreaks)`
    --font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    font-size: var(--font-size);
    line-height: ${getCssSizeValue(57, BREAKPOINTS.MOBILE)};
    letter-spacing: calc(var(--font-size) * -0.04);
    --circle-size: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        --font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
        --circle-size: ${getCssSizeValue(44, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getFontSize(110, BREAKPOINTS.DESKTOP)};
        line-height: ${getCssSizeValue(136, BREAKPOINTS.DESKTOP)};
        --circle-size: ${getCssSizeValue(78, BREAKPOINTS.DESKTOP)};
        transform: unset;
        //max-width: ${getCssSizeValue(1024, BREAKPOINTS.DESKTOP)};
    }
    
    .icon{
        border-color: ${({ theme }) => theme.colors.black};
    }
`
const Links = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    row-gap: ${getCssSizeValue(35, BREAKPOINTS.MOBILE)};
    line-height: ${getCssSizeValue(21, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        row-gap: ${getCssSizeValue(22, BREAKPOINTS.DESKTOP)};
        line-height: ${getCssSizeValue(34, BREAKPOINTS.DESKTOP)};
    }
`

const linkCss = css`
    --font-size: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
    text-transform: uppercase;
    letter-spacing: calc(var(--font-size) * -0.04);
    font-size: var(--font-size);

    &:before{
        background: currentColor;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getCssSizeValue(36, BREAKPOINTS.DESKTOP)};
    }
`

const StyledLink = styled(CommonLink)`
    ${linkCss};
`

const StyledExternalLink = styled.a`
    ${linkCss};
    text-decoration: none;
    color: var(--secondary-color);
    max-width: max-content;
    position: relative;
`

const LinkWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Icon = styled(CommonIcon)`
    width: 15px;
    transform: rotate(90deg);
    transition: var(--transition-duration) var(--ease);
    &.hover{
        transform: translateX(50%) rotate(90deg);
    }
`

function formatLink(name='',href='', isExternal=false){
    return ({
        text: name || '',
        href: href || '',
        isExternal
    })
}
const Link = ({ href, children, isExternalLink = false, ...props }) => {
    const [hover, setHover] = useState(false)

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };
    return (
        <LinkWrapper >
            {!isExternalLink ? (
                <StyledLink
                    href={href}
                    className={`underline-animation`}
                    data-cursor={CURSOR_TYPES.LINK}
                    onPointerEnter={handleMouseEnter} onPointerLeave={handleMouseLeave}
                >
                    {children}
                </StyledLink>
            ) : (
                <StyledExternalLink
                    target='_blank'
                    href={href}
                    className={`underline-animation`}
                    data-cursor={CURSOR_TYPES.LINK}
                    onPointerEnter={handleMouseEnter} onPointerLeave={handleMouseLeave}
                >
                    {children}
                </StyledExternalLink>
            )}
            <Icon name={ICONS.ARROWUP} className={`${hover ? 'hover' : ''} `} color="secondary"/>
        </LinkWrapper>
    )
}

const CheckOthers = ({ data, ...props }) => {
    const router = useRouter()
    const { text, contactHeader='', id='', checkOthersHeader ='', links=[], externalLinks = [], isColorfulBackground = false } = data
    const textColor = useMemo(() =>  isColorfulBackground ? 'white' : 'black',[isColorfulBackground])
    const allLinks = useMemo(() => {
        const local = links.map((link) => formatLink(link?.name, `/${link?.slug || ''}`, false))
        const external = externalLinks.map((link) => formatLink(link?.text, link?.url, true))
        return mergeArrays(local, external)
    },[links, externalLinks])
    const textBreakData = useMemo(() => ({ parentId: text?.id || '', ...text?.text } || {}),[text])
    const finalId = getIdWithoutSpecialSymbols(id);

    const handleRedirectToContactPage = () => {
        router.push('/contact')
    }

    return (
        <Container id={finalId} data-cursor={CURSOR_TYPES.HTML} data-color-scheme={THEME_COLOR_SCHEMES.PLEO_MAIN} {...props} >
            <Side className={`left ${isColorfulBackground ? 'color-bg' : ''}`} onClick={handleRedirectToContactPage}>
                <Text color={textColor} className="tag">
                        {contactHeader}
                </Text>
                <Reveal onInView animationVars={{
                    delay
                }}>
                    <TextWithBreaks data={textBreakData} color={textColor} />
                </Reveal>
            </Side>
            <Side className="px-main right">
                <Text className="tag">
                    {checkOthersHeader}
                </Text>
                <Links>
                    {allLinks.length > 0 && (
                        allLinks.map((link, index) => {
                            if(!link?.href) return
                            const isExternal = link['isExternal']
                            return (
                                <Link isExternalLink={isExternal} key={index} href={link.href}>
                                    <Reveal onInView animationVars={{
                                        delay: delay + index * 0.1
                                    }}>
                                        {link.text}
                                    </Reveal>
                                </Link>
                            )
                        })
                    )}
                </Links>
            </Side>
        </Container>
    );
};

export default CheckOthers;
